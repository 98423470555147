#bars {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bar {
    background: #52467b;
   
    height: 30px;
    width: 10px;
    margin: 0px 4px;
    border-radius: 5px;
    transform-origin: center;
    animation: sound 2s ease-in-out infinite alternate;
}

@keyframes sound {
    0% {
        opacity: .35;
        height: 0px; 
    }
    100% {
        opacity: 1;       
        height: 60px;        
    }
}


  .hover\:shadow-rounded:hover {
    box-shadow: 
      4px 0 5px -2px rgba(0, 0, 0, 0.2), /* right shadow */
      -4px 0 5px -2px rgba(0, 0, 0, 0.2), /* left shadow */
      0 4px 5px -2px rgba(0, 0, 0, 0.2), /* bottom shadow */
      0 -4px 5px -2px rgba(0, 0, 0, 0.2); /* top shadow */
  }